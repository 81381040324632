import React, { ReactElement } from 'react'
import {
  SectionOne,
  SectionTwo,
  SectionThree,
  SectionFour,
  Layout,
  SEO,
} from 'app/components'

const IndexPage = (): ReactElement => (
  <Layout headerColors={[null, null, null, null, null]}>
    <SEO title='Крепость' />
    <SectionOne />
    <SectionTwo />
    <SectionThree />
    <SectionFour />
  </Layout>
)

export default IndexPage
